import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { useTranslation } from "react-i18next";

import type { LoaderFunctionArgs } from "@remix-run/node";

import { Button } from "./components/Button";
import { LinkButton } from "./components/LinkButton";
import { PwaMeta } from "./components/PwaMeta";
import { PageSectionHeading, Paragraph } from "./components/Typography";
import { remixI18next } from "./libs/i18n/i18next.server";
import { caseTokenCookie } from "./cookies";

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = await remixI18next.getLocale(request);
  const authHeader = request.headers.get("authorization");
  const headers = new Headers();
  if (authHeader) {
    const caseToken = authHeader.split(" ")[1];
    headers.append("Set-Cookie", await caseTokenCookie.serialize(caseToken));
  }

  return json({ locale }, { headers });
}

export const handle = {
  i18n: "common",
};

function AppRoot() {
  const { locale } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();

  return (
    <html lang={locale} dir={i18n.dir()} className="min-h-full">
      <head>
        <title>Rehaber</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1, viewport-fit=cover, width=device-width maximum-scale=1"
        />

        <>
          {/*  Favicons */}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/app-icons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/app-icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/app-icons/favicon-16x16.png"
          />
          <link rel="manifest" href="/manifest.webmanifest" />
          <link
            rel="mask-icon"
            href="/app-icons/safari-pinned-tab.svg"
            color="#000000"
          />
          <link rel="shortcut icon" href="/app-icons/favicon.ico" />
          <meta name="msapplication-TileColor" content="#2b5797" />
          <meta
            name="msapplication-config"
            content="/app-icons/browserconfig.xml"
          />
        </>
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"
            rel="stylesheet"
          />
        </>
        <PwaMeta />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(AppRoot);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-neutral-200 p-4 py-16">
        <PageSectionHeading>Ein Fehler ist aufgetreten</PageSectionHeading>
        <Paragraph>Bitte lade die Seite neu.</Paragraph>
        <Button onClick={() => location.reload()}>Neu laden</Button>
        <Paragraph>
          Sollte der Fehler weiterhin auftreten, setze deine Daten zurück
        </Paragraph>
        <LinkButton to="/logout">Zurücksetzen</LinkButton>
        <Scripts />
      </body>
    </html>
  );
}
